











import { Vue, Component } from 'vue-property-decorator';
import Navigation from '../component/navigation.vue';
import { Getter, namespace } from 'vuex-class';

@Component({
  components: { Navigation }
})
export default class Dashboard extends Vue {

  @(namespace("account").Getter("isAuthenticated")) isAuthenticated;
  dialog: boolean = false;

}
