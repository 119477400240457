



























































































































































































































































































































































































































































































































































import {Vue, Component, Watch} from "vue-property-decorator";
import { api } from '../../api/Api';
import {IntToBetaalwijze, IntToGeslacht, IntToLidType} from "../../utils/Enums";
import FormSelect from "../../component/formElements/formSelect.vue";
import FormDate from "../../component/formElements/formDate.vue";
import FormNumber from "../../component/formElements/formNumber.vue";
import FormCheckbox from "../../component/formElements/formCheckbox.vue";
import FormTextarea from "../../component/formElements/formTextarea.vue";
@Component({
  components: {FormTextarea, FormCheckbox, FormNumber, FormDate, FormSelect}
})
export default class LedenAll extends Vue {
  private leden = [];
  private landen() {
    return Array.from(new Set(this.leden.map((x : any) => x.land)));
  }

  private selected = [];
  private overlay = false;
  private valid : boolean = false;
  private busy : boolean = false;


  private fields = localStorage.fields != null ? JSON.parse(localStorage.fields) : {
    voorletters:true,
    roepnaam:true,
    tussenvoegsel:true,
    naam:true,
    geslacht:true,
    adres:true,
    huisnummer:true,
    toevoeging:true,
    postcode:true,
    plaats:true,
    land:true,
    soortlid:true,
    inschrijving:true,
    eindinschrijving:true,
    email:true,
    telefoon:true,
    mobiel:true,
    voldaan:true,
    vrijveld1:true,
    vrijveld2:true,
    bankreknr:true,
    tennamevan:true,
    betaalwijze:true,
    aantaltermijnen:true,
    bedrag:true,
    betaald:true,
    openstaand:true
  }

  @Watch('fields', {deep: true})
  onFieldsChanged(val: any) {
    localStorage.fields = JSON.stringify(val);
  }

  //#region filters
  private filters = localStorage.filters != null ? JSON.parse(localStorage.filters) : {
    openstaand: [],
    type: [],
    land: [],
    geldig: [],
    voldaan: [],
    betaalwijze: []
  }
  @Watch('filters', {deep: true})
  onFilterssChanged(val: any) {
    localStorage.filters = JSON.stringify(val);
  }

  private filterActive() {
    return this.filters.openstaand.length != 0 ||
      this.filters.type.length != 0  ||
      this.filters.land.length != 0  ||
      this.filters.geldig.length != 0  ||
      this.filters.voldaan.length != 0  ||
      this.filters.betaalwijze.length != 0 ;
  }

  private resetFilters() {
    this.filters = {
      openstaand: [],
      type: [],
      land: [],
      geldig: [],
      voldaan: [],
      betaalwijze: []
    }
  }

  private filterdLeden() {
    return this.leden.filter((x: any)  =>
        (this.filters.openstaand.length > 0 ? (
            (this.filters.openstaand.includes('Betaald') ? x.bedrag == x.betaald : false ) ||
            (this.filters.openstaand.includes('Teveel betaald') ? x.bedrag < x.betaald : false ) ||
            (this.filters.openstaand.includes('Teweinig betaald') ? x.bedrag > x.betaald : false )
        ) : true) &&
        (this.filters.type.length > 0 ? this.filters.type.includes(x.soortlid) : true) &&
        (this.filters.land.length > 0 ? this.filters.land.includes(x.land) : true) &&
        (this.filters.voldaan.length > 0 ? this.filters.voldaan.includes(x.voldaan) : true) &&
        (this.filters.betaalwijze.length > 0 ? this.filters.betaalwijze.includes(x.betaalwijze) : true) &&
        (this.filters.geldig.length > 0 ? (
            (this.filters.geldig.includes('Geldig') ? this.testInschrijvingGeldig(x.inschrijving, x.eindinschrijving) : false) ||
            (this.filters.geldig.includes('Verlopen') ? this.testInschrijvingVerlopen(x.inschrijving, x.eindinschrijving) : false) ||
            (this.filters.geldig.includes('Toekomst') ? this.testInschrijvingToekomst(x.inschrijving, x.eindinschrijving) : false) ||
            (this.filters.geldig.includes('Niet compleet') ? this.testInschrijvingInvalid(x.inschrijving, x.eindinschrijving) : false)
        ) : true)
    );
  }

  private testInschrijvingGeldig(van: any, tot: any){
    return Date.now() >= Date.parse(van) && Date.now() <= Date.parse(tot);
  }

  private testInschrijvingVerlopen(van: any, tot: any){
    return Date.now() >= Date.parse(tot);
  }

  private testInschrijvingToekomst(van: any, tot: any){
    return Date.now() <= Date.parse(van);
  }

  private testInschrijvingInvalid(van: any, tot: any){
    return isNaN(Date.parse(van)) || isNaN(Date.parse(tot));
  }
  //#endregion

  mounted() {
    if(this.$route.query.f != null) {
      this.resetFilters();
      if(this.$route.query.f == "openstaand") {
        this.filters.openstaand = ["Teweinig betaald"];
      } else if (this.$route.query.f == "verlopen") {
        this.filters.geldig = ["Verlopen"];
      }
    }

    this.update();
  }

  async update() {
    let ledenPre = await api.get('leden/getall');
    ledenPre.forEach((obj: any) => {
      obj.geslacht = IntToGeslacht(obj.geslacht);
      obj.betaalwijze = IntToBetaalwijze(obj.betaalwijze);
      obj.soortlid = IntToLidType(obj.soortlid);
      obj.openstaand = (obj.bedrag - obj.betaald).toFixed(2);
      obj.voldaan = (obj.bedrag <= obj.betaald);
      obj.inschrijvingFormated = this.formatDate(obj.inschrijving);
      obj.eindinschrijvingFormated = this.formatDate(obj.eindinschrijving);
    });
    this.leden = ledenPre;
  }

  private editItem(val: any){
    this.$router.push({path: `/leden/${val}`,});
  }

  private search = '';

  computedHeaders() : []{
    let ret = this.headerOptions.filter(x => x.always || this.fields[x.value] == true);
    ret.forEach(function(option){
      option.sortable = true;
    })
    return ret;
  }

  private headerOptions = [
      {value: 'lidnr', text: "Lid nummer", always: true},
      {value: 'voorletters', text: "Voorletters"},
      {value: 'roepnaam', text: "Roepnaam"},
      {value: 'tussenvoegsel', text: "Tussenvoegsel"},
      {value: 'naam', text: "Naam"},
      {value: 'geslacht', text: "Geslacht"},
      {value: 'adres', text: "Adres"},
      {value: 'huisnummer', text: "Huisnummer"},
      {value: 'toevoeging', text: "Toevoeging"},
      {value: 'postcode', text: "Postcode"},
      {value: 'plaats', text: "Plaats"},
      {value: 'land', text: "Land"},
      {value: 'soortlid', text: "Soortlid"},
      {value: 'inschrijving', text: "Inschrijving"},
      {value: 'eindinschrijving', text: "Eindinschrijving"},
      {value: 'email', text: "Email"},
      {value: 'telefoon', text: "Telefoon"},
      {value: 'mobiel', text: "Mobiel"},
      {value: 'vrijveld1', text: "Vrijveld1"},
      {value: 'vrijveld2', text: "Vrijveld2"},
      {value: 'bankreknr', text: "Bankreknr"},
      {value: 'tennamevan', text: "Tennamevan"},
      {value: 'betaalwijze', text: "Betaalwijze"},
      {value: 'aantaltermijnen', text: "Aantaltermijnen"},
      {value: 'bedrag', text: "Bedrag"},
      {value: 'betaald', text: "Betaald"},
      {value: 'openstaand', text: "Openstaand"},
      {value: 'voldaan', text: "Voldaan"}

  ];

  async hookSave(item : any){
    item.openstaand = (item.bedrag - item.betaald).toFixed(2);
    item.voldaan = (item.bedrag <= item.betaald);

    const response = await api.post(`leden/edit`, {
      lidnr: item.lidnr,
      voorletters: item.voorletters,
      naam: item.naam,
      roepnaam: item.roepnaam,
      tussenvoegsel: item.tussenvoegsel,
      geslacht: item.geslacht,
      adres: item.adres,
      huisnummer: item.huisnummer,
      toevoeging: item.toevoeging,
      plaats: item.plaats,
      postcode: item.postcode,
      land: item.land,
      soortlid: item.soortlid,
      inschrijving: item.inschrijving,
      eindinschrijving: item.eindinschrijving,
      email: item.email,
      telefoon: item.telefoon,
      mobiel: item.mobiel,
      vrijveld1: item.vrijveld1,
      vrijveld2: item.vrijveld2,
      bankreknr: item.bankreknr,
      tennamevan: item.tennamevan,
      betaalwijze: item.betaalwijze,
      aantaltermijnen: item.aantaltermijnen,
      bedrag: item.bedrag,
      betaald: item.betaald,
    });
  }

  private menuInschrijving = false;
  private menuEindinschrijving = false;

  private saveInschrijving(date) {
    date.item.inschrijvingFormated = this.parseDate(date.item.inschrijving);
    this.$refs.menuInschrijving.save(date.value);
  }

  private saveEindinschrijving(date) {
    date.item.eindinschrijvingFormated = this.parseDate(date.item.eindinschrijving);
    this.$refs.menuEindinschrijving.save(date.value);
  }

  private formatDate (date) {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return `${day}-${month}-${year}`
  }

  private parseDate (date) {
    if (!date) return null

    const [day, month, year] = date.split('-')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  }

  private updateOpenstaand() {
    let bedrag = this.$refs.bedrag.value();
    let betaald = this.$refs.betaald.value();

    if(bedrag != null && betaald != null) {
      this.$refs.openstaand.setValue((bedrag - betaald).toFixed(2));
      this.$refs.voldaan.setValue(bedrag <= betaald);
    }
  }

  private async submit(){
    if (this.busy) return;
    this.busy = true;

    await Promise.all(this.selected.map(async (lid : any) => {
      lid.soortlid = this.$refs.soortlid.value() != "Niet aanpassen" ? this.$refs.soortlid.value() : lid.soortlid;
      lid.inschrijving = this.$refs.inschrijving.value() ?? lid.inschrijving;
      lid.eindinschrijving = this.$refs.eindinschrijving.value() ?? lid.eindinschrijving;
      lid.betaalwijze = this.$refs.betaalwijze.value() != "Niet aanpassen" ? this.$refs.betaalwijze.value() : lid.betaalwijze;
      lid.aantaltermijnen = this.$refs.aantaltermijnen.value() ?? lid.aantaltermijnen ;
      lid.bedrag = this.$refs.bedrag.value() ?? lid.bedrag;
      lid.betaald = this.$refs.betaald.value() ?? lid.betaald;
      await api.post('leden/edit', lid);
    }));

    this.busy = false;
    this.$router.go();
  }
}
