















































































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import {api} from "../../api/Api";
import {IntToBetaalwijze, IntToGeslacht, IntToLidType} from "../../utils/Enums";

@Component
export default class DashboardCards extends Vue {

  private leden = [];

  mounted() {
    this.update();
  }

  async update() {
    let ledenPre = await api.get('leden/getall');
    ledenPre.forEach(function(obj: any){
      obj.geslacht = IntToGeslacht(obj.geslacht);
      obj.betaalwijze = IntToBetaalwijze(obj.betaalwijze);
      obj.soortlid = IntToLidType(obj.soortlid);
    });
    this.leden = ledenPre;
  }

  @Watch('leden', {deep: true})
  onFieldsChanged(val: any) {
    this.ledenCount = this.leden.length.toString();
    this.ledenOpenstaand = this.leden.filter((x: any) => x.betaald < x.bedrag).length.toString();
    this.ledenVerlopen = this.leden.filter((x: any) => Date.now() >= Date.parse(x.eindinschrijving)).length.toString();
    this.ledenLanden = (new Set(this.leden.map((x : any) => x.land))).size.toString();
  }

  private ledenCount = '-';
  private ledenOpenstaand = '-';
  private ledenVerlopen = '-';
  private ledenLanden = '-';

}

