































import {Vue, Component, Watch, Prop} from "vue-property-decorator";

@Component
export default class FormDate extends Vue {
  @Prop({required:true})
  public title : string;

  @Prop({default: 'mdi-calendar'})
  public icon : string;

  @Prop({default: false})
  public future : boolean;


  @Prop({default: null})
  public defaultValue : Date;

  private dateFormatted = this.formatDate(this.defaultValue);
  private date = this.defaultValue;
  private menu = false;


  @Watch('menu')
  OnPropertyChanged(val: string, oldvalue: string) {
    val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
  }

  @Watch('date')
  OnPropertyChanged(val: string, oldvalue: string) {
    this.dateFormatted = this.formatDate(this.date)
  }

  public value() {
    return this.date;
  }

  private save(date) {
    this.$refs.menu.save(date);
  }

  private formatDate (date) {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return `${day}-${month}-${year}`
  }

  private parseDate (date) {
    if (!date) return null

    const [day, month, year] = date.split('-')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  }

}

