








import { Vue, Component } from 'vue-property-decorator';
import DashboardCards from "./cards.vue";
import DashboardTable from "./table.vue";
import DashboardCharts from "./charts.vue";

@Component({
  components: {
    DashboardCards,
    DashboardTable,
    DashboardCharts
  }
})
export default class Home extends Vue {


}
