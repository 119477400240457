
































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import FormDate from '../../component/formElements/formDate.vue';
import FormCheckbox from "../../component/formElements/formCheckbox.vue";
import FormRadio from "../../component/formElements/formRadio.vue";
import FormEmail from "../../component/formElements/formEmail.vue";
import FormNumber from "../../component/formElements/formNumber.vue";
import FormSelect from "../../component/formElements/formSelect.vue";
import FormText from "../../component/formElements/formText.vue";
import FormTextarea from "../../component/formElements/formTextarea.vue";
import {api} from "../../api/Api";
import {IntToBetaalwijze, IntToGeslacht, IntToLidType} from "../../utils/Enums";

@Component({
  components: {FormTextarea, FormText, FormSelect, FormNumber, FormEmail, FormRadio, FormCheckbox, FormDate }
})
export default class LedenAdd extends Vue {
  private valid : boolean = false;
  private busy : boolean = false;
  private errorMessage: string = "";

  mounted() {
   this.update();
  }

  async update() {
    let nummer = await api.get('leden/freeNumber');
    this.$refs.lidnr.setValue(nummer);
  }

  private updateOpenstaand() {
    let bedrag = this.$refs.bedrag.value();
    let betaald = this.$refs.betaald.value();

    if(bedrag != null && betaald != null) {
      this.$refs.openstaand.setValue((bedrag - betaald).toFixed(2));
      this.$refs.voldaan.setValue(bedrag <= betaald);
    }
  }

  private async checkLidNr() {
    let lidnrField = this.$refs.lidnr;
    let lidnr = lidnrField.value();

    if(lidnr != null) {
        let lidnrAvailable = await api.get(`leden/check/${lidnr}`);
        lidnrField.error = !lidnrAvailable.success;
        lidnrField.errorMessage = lidnrAvailable.success ? "" : "Lidnr bestaat al";
    }
  }

  private async submit(){
    // Don't allow multiple authentication requests at once
    if (this.busy) return;
    this.busy = true;

    const response = await api.post(`leden/add`, {
      lidnr: this.$refs.lidnr.value(),
      voorletters: this.$refs.voorletters.value(),
      naam: this.$refs.naam.value(),
      roepnaam: this.$refs.roepnaam.value(),
      tussenvoegsel: this.$refs.tussenvoegsel.value(),
      geslacht: this.$refs.geslacht.value(),
      adres: this.$refs.adres.value(),
      huisnummer: this.$refs.huisnummer.value(),
      toevoeging: this.$refs.toevoeging.value(),
      plaats: this.$refs.plaats.value(),
      postcode: this.$refs.postcode.value(),
      land: this.$refs.land.value(),
      soortlid: this.$refs.soortlid.value(),
      inschrijving: this.$refs.inschrijving.value(),
      eindinschrijving: this.$refs.eindinschrijving.value(),
      email: this.$refs.email.value(),
      telefoon: this.$refs.telefoon.value(),
      mobiel: this.$refs.mobiel.value(),
      vrijveld1: this.$refs.vrijveld1.value(),
      vrijveld2: this.$refs.vrijveld2.value(),
      bankreknr: this.$refs.bankreknr.value(),
      tennamevan: this.$refs.tennamevan.value(),
      betaalwijze: this.$refs.betaalwijze.value(),
      aantaltermijnen: this.$refs.aantaltermijnen.value(),
      bedrag: this.$refs.bedrag.value(),
      betaald: this.$refs.betaald.value(),
    });

    if(response.errors.length == 0){
      this.errorMessage = "Gebruiker is opgeslagen."
    } else {
      response.errors.forEach((error: any) => {
        this.$refs[error.field].errorMessage = error.error_code;
      })
      this.errorMessage = "Er waren fouten gevonden."
    }


    this.busy = false;
  }

}

