export function IntToGeslacht(value: number): string {
    switch (value){
        case 0:
            return "Man";
        case 1:
            return "Vrouw";
        default:
            return "Overige";
    }
}

export function IntToLidType(value: number): string {
    switch (value){
        case 0:
            return "";
        case 1:
            return "Overige";
        case 2:
            return "Ere Lid";
        default:
            return "Normaal Lid";
    }
}

export function IntToBetaalwijze(value: number): string {
    switch (value){
        case 0:
            return "Handmatig";
        case 1:
            return "Automatische incasso";
        default:
            return "nvt";
    }
}