import Vue from 'vue';
import Vuex from 'vuex';
import * as account from './module/account';

// Add Vuex to Vue
Vue.use(Vuex);

// Export store
export const store = new Vuex.Store({
    modules: {
        account,
    }
});
