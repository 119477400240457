import Vue from 'vue';
import VueRouter from 'vue-router';

// @ts-ignore
const mainRoutes = [
    {
        path: '/login',
        component: () => import('../layouts/login.vue'),
        children: [
            {
                name: 'login',
                path: '',
                component: () => import('./login/login.vue')
            },
            {
                name: 'logout',
                path: 'logout',
                component: () => import('./login/Logout.vue')
            }
        ]
    },
    {
        path: '/dashboard',
        meta: {auth: true},
        component: () => import('../layouts/dashboard.vue'),
        children: [
            {
                name: 'dashboard-home',
                path: '',
                component: () => import('./dashboard/home.vue')
            },
            {
                name: 'database',
                path: '/database',
                component: () => import('./database/backup.vue')
            },
            {
                name: 'etiketten',
                path: '/etiketten',
                component: () => import('./etiketten/create.vue')
            },
            {
                name: 'leden',
                path: '/leden',
                component: () => import('./leden/all.vue')
            },
            {
                name: 'leden-add',
                path: '/leden/add',
                component: () => import('./leden/add.vue')
            },
            {
                name: 'leden-view',
                path: '/leden/:id',
                component: () => import('./leden/view.vue')
            },
            {
                name: 'settings',
                path: '/settings',
                component: () => import('./settings/settings.vue')
            }
        ]
    },
];

const specialRoutes = [
    {
        name: 'index',
        path: '/',
        redirect: { name: 'dashboard-home'}
    },
    {
        name: 'not-found',
        path: '*',
        component: () => import('./statuscodes/404.vue')
    }
];

// Create router
Vue.use(VueRouter);
export const router = new VueRouter({
    mode: 'history',
    routes: [
        ...mainRoutes,
        ...specialRoutes // Should be last because of the 404 handler
    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});
