



















































import { Vue, Component } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import Form from "../../utils/Form";

@Component
export default class Login extends Vue {
  @(namespace("account").Action("authenticate")) authenticate;
  @(namespace("account").Getter("isAuthenticated")) isAuthenticated;

  private form: Form = {
    busy: false,
    error: null,
    fields: {
      email: {
        value: "",
        error: null
      },
      password: {
        value: "",
        error: null
      }
    }
  };

  async submit() {
    // Don't allow multiple authentication requests at once
    if (this.form.busy) return;
    this.form.busy = true;

    for (const key of Object.keys(this.form.fields))
      this.form.fields[key].error = null;

    const response = await this.authenticate({
      email: this.form.fields.email.value,
      password: this.form.fields.password.value
    });

    for (const error of response.errors) {
      if (error.field in this.form.fields)
        this.form.fields[error.field].error = error.error_code;
      else this.form.error = error.error_code;
    }

    this.form.busy = false;

    if (response.success) {
      const redirect = this.$router.currentRoute.query.redirect;
      if (redirect) {
        this.$router.push(atob(redirect.toString()));
      } else {
        this.$router.push({ name: "index" });
      }
    }
  }
}
