











import {Vue, Component, Watch, Prop} from "vue-property-decorator";

@Component
export default class FormText extends Vue {
  @Prop({required:true})
  public title : string;

  @Prop({default: false})
  public readonly : boolean;

  @Prop({default: ''})
  public icon : string;

  @Prop({default: null})
  public defaultValue : Date;

  private text = this.defaultValue;

  public value(){
    return this.text;
  }

}

