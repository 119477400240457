










import {Vue, Component, Watch, Prop} from "vue-property-decorator";

@Component
export default class FormEmail extends Vue {
  @Prop({required:true})
  public title : string;

  @Prop({default: ''})
  public icon : string;

  @Prop({default: null})
  public defaultValue : Date;

  private email = this.defaultValue;

  public value(){
    return this.email;
  }

  private rules = {
    email: value => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return value == "" || pattern.test(value) || 'Ongeldige email'
    }
  }
}

