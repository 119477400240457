import './utils/developmentOptions';
import Vue from 'vue';
import { router } from './route';
import { store } from './store/index';
import App from './component/app.vue';
// @ts-ignore
import vuetify from './plugins/vuetify';

// Add authentication guard
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth) && !store.getters['account/isAuthenticated']) {
        next({
            name: 'login',
            query: {
                redirect: btoa(to.fullPath)
            }
        });

        return;
    }

    next();
});



new Vue({
    router,
    store,
    // @ts-ignore
    vuetify,
    render: h => h(App),
    el: 'app',
    components: {
        App
    }
});


