
















import {Vue, Component, Watch, Prop} from "vue-property-decorator";

@Component
export default class FormNumber extends Vue {
  @Prop({required:true})
  public title : string;

  @Prop({default: ''})
  public icon : string;

  @Prop({default: 1})
  public step : number;

  @Prop({default: false})
  public readonly : boolean;

  @Prop({default: null})
  public defaultValue : number;

  @Prop({default: null})
  public prefix : string;

  @Prop({default: -99999999})
  public min : number;

  private number: number = this.defaultValue;
  public error : boolean = false;
  public errorMessage : string = "";

  public value(){
    return this.number;
  }

  public setValue(val: number){
    this.number = val;
  }

  @Watch('number')
  OnPropertyChanged(val: string) {
    console.log(this.min)
    let intVal = parseInt(val)
    if(intVal < this.min) {
      val = this.min.toString()
      this.number = this.min;
    }
    this.$emit('change', val);
  }

}

