














import {Vue, Component, Watch, Prop} from "vue-property-decorator";

@Component
export default class FormTextarea extends Vue {
  @Prop({required:true})
  public title : string;

  @Prop({default: null})
  public defaultValue : Date;

  @Prop({default: 13})
  public rows : Number;

  private text = this.defaultValue;

  public value(){
    return this.text;
  }

}

