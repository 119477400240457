
















































































import { Vue, Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';


@Component
export default class Navigation extends Vue {
  private drawer = null;
  private dark = localStorage.darkTheme != null ? JSON.parse(localStorage.darkTheme) : false;

  created() {
    this.$vuetify.theme.dark = this.dark;
  }


  @(namespace("account").Getter("isAuthenticated")) isAuthenticated;

  private items = [
    { icon: 'mdi-home', text: 'Dashboard', to: 'dashboard-home' },
    { icon: 'mdi-account-multiple', text: 'Alle leden', to: 'leden' },
    { icon: 'mdi-account-plus', text: 'Lid Toevoegen', to: 'leden-add' },
    { icon: 'mdi-printer', text: 'Etiketten', to: 'etiketten' },
    // { icon: 'mdi-database', text: 'Database', to: 'database' },
    // { icon: 'mdi-cog', text: 'Instellingen', to: 'settings' },
  ]

  private accountItems = [
    { icon: 'mdi-logout-variant', text: 'Logout', to: 'logout' },
    // { icon: 'mdi-cog', text: 'Instellingen', to: 'settings' }
  ]

  private changeTheme(){
    this.dark = !this.dark;
    localStorage.darkTheme = JSON.stringify(this.dark);
    this.$vuetify.theme.dark = this.dark;

  }
}
