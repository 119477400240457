









import {Vue, Component, Watch, Prop} from "vue-property-decorator";

@Component
export default class FormSelect extends Vue {
  @Prop({required:true})
  public title : string;

  @Prop({default: 'mdi-email'})
  public icon : string;

  @Prop({default: []})
  public items;

  @Prop({default: null})
  public defaultValue : string;

  private selected = this.defaultValue;

  public value(){
    return this.selected;
  }

}

