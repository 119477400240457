






import { Vue, Component } from "vue-property-decorator";
import {api} from "../../api/Api";
import {IntToBetaalwijze, IntToGeslacht, IntToLidType} from "../../utils/Enums";

@Component
export default class DashboardTable extends Vue {
  private leden = [];
  private landen = new Map();
  private types = new Map();

  mounted() {
    this.update();
  }

  async update() {
    let ledenPre = await api.get('leden/getall');
    ledenPre.forEach(function(obj: any){
      obj.geslacht = IntToGeslacht(obj.geslacht);
      obj.betaalwijze = IntToBetaalwijze(obj.betaalwijze);
      obj.soortlid = IntToLidType(obj.soortlid);
    });
    this.leden = ledenPre;
    this.landen = count(this.leden.map((l : any) => l.land));
    this.types = count(this.leden.map((l : any) => l.soortlid));
  }
}


function count(arr: any[]) {
  return arr.reduce((acc, val) => acc.set(val, 1 + (acc.get(val) || 0)), new Map());
}

