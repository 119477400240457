









import {Vue, Component, Watch, Prop} from "vue-property-decorator";

@Component
export default class FormCheckbox extends Vue {
  @Prop({required:true})
  public title : string;

  @Prop({default: false})
  public checked : boolean;

  @Prop({default: false})
  public readonly : boolean;

  private check = this.checked;

  public value() {
    return this.check;
  }

  public setValue(val : boolean){
    this.check = val;
  }
}

