import {apiBase } from '../env';

const TOKEN_KEY = 'ACCOUNT_TOKEN';

export class Api {
    private url: string;
    private token: string | null = null;

    constructor(url: string, token: string | null = null) {
        this.url = url;
        this.token = token || localStorage.getItem(TOKEN_KEY) || null;
    }

    // Auth
    getToken() {
        return this.token;
    }

    setToken(token: string | null, persist = false) {
        this.token = token;

        // Persist token
        if (persist) {
            if (!token) localStorage.removeItem(TOKEN_KEY);
            else localStorage.setItem(TOKEN_KEY, token);
        }
    }

    // Utils
    resolvePath(path: string) {
        //TODO improve
        return `${this.url}${path}`;
    }

    // HTTP
    private getDefaultRequestOptions(): RequestInit {
        const options = {
            headers: new Headers()
        };

        const token = this.getToken();
        if (token)
            options.headers.append('Authorization', `Bearer ${token}`);

        return options;
    }

    private async getResponse(response: Response) {
        // Unauthenticated or permission issue
        if (response.status == 401) throw response.statusText;

        // Success, no content
        if (response.status == 204) return null;

        return response.json();
    }

    async get(path: string) {
        const url = this.resolvePath(path);
        const options = this.getDefaultRequestOptions();
        options.method = 'GET';

        return fetch(url, options).then(this.getResponse);
    }

    async post(path: string, body: Object|null = {}) {
        const url = this.resolvePath(path);
        const options = this.getDefaultRequestOptions();
        options.method = 'POST';

        if (body != null) {
            // @ts-ignore
            options.headers.append('Content-Type', 'application/json');
            options.body = JSON.stringify(body);
        }

        return fetch(url, options).then(this.getResponse);
    }

    async delete(path: string, body: Object|null = null) {
        const url = this.resolvePath(path);
        const options = this.getDefaultRequestOptions();
        options.method = 'DELETE';

        if (body != null) {
            // @ts-ignore
            options.headers.append('Content-Type', 'application/json');
            options.body = JSON.stringify(body);
        }

        return fetch(url, options).then(this.getResponse);
    }
}

export const api = new Api(apiBase);
