










import { Vue, Component } from 'vue-property-decorator';
import { Action, namespace } from 'vuex-class';

@Component
export default class Logout extends Vue {
  @(namespace("account").Action("endSession")) endSession;

  mounted() {
    this.endSession();
  }
}
