import { api } from '../../api/Api';
import jwtDecode from 'jwt-decode';

export const namespaced = true;

export const state = {
    token: api.getToken() || null
};


export const getters = {
    isAuthenticated: state => state.token != null && jwtDecode(state.token).exp > (Date.now() / 1000),
    tokenDecoded: state => state.token != null ? jwtDecode(state.token) : null
};

export const actions = {
    async authenticate({ commit }, { email, password }) {
        const response = await api.post('authorization/login', { email, password });

        if (response.token)
            commit('setToken', response.token);

        return {
            errors: response.errors || [],
            success: response.success || false,
        };
    },

    async endSession({ commit }) {
        commit('setToken', null);
    }
};

export const mutations = {
    setToken(state, token) {
        state.token = token;
        api.setToken(token, true);
    }
};
