




















import {Vue, Component, Watch, Prop} from "vue-property-decorator";

@Component
export default class FormRadio extends Vue {
  @Prop({required:true})
  public title : string;

  @Prop({default: null})
  public options : string;

  @Prop({default: null})
  public defaultValue : string;

  private radioGroup = this.defaultValue;

  public value(){
    return this.radioGroup;
  }
}

