



























































import { Vue, Component } from "vue-property-decorator";
import {api} from "../../api/Api";
import {IntToBetaalwijze, IntToGeslacht, IntToLidType} from "../../utils/Enums";
import FormTextarea from "../../component/formElements/formTextarea.vue";
import FormNumber from "../../component/formElements/formNumber.vue";
import FormSelect from "../../component/formElements/formSelect.vue";
import FormEmail from "../../component/formElements/formEmail.vue";
import FormText from "../../component/formElements/formText.vue";
import FormRadio from "../../component/formElements/formRadio.vue";
import FormDate from "../../component/formElements/formDate.vue";
import FormCheckbox from "../../component/formElements/formCheckbox.vue";

@Component({
  components: {FormTextarea, FormText, FormSelect, FormNumber, FormEmail, FormRadio, FormCheckbox, FormDate }
})
export default class Etiketten extends Vue {
  private leden = [];
  private pageWidth = 210;
  private pageHeight = 297
  private x = 3;
  private y = 7;
  private xPadding = 0;
  private yPadding = 0;
  private xSize = 65;
  private ySize = 38;
  private xMargin = 7;
  private yMargin = 16;


  mounted() {
    this.update();
  }

  pages(): number{
    return Math.ceil(this.leden.length / (this.x * this.y));
  }

  getCellContent(page: number, tableX: number, tableY: number): any {

    let perPage = this.x * this.y;
    let pageIndex = page -1;
    let start = pageIndex * perPage;
    let index = this.getContentCords(tableX, tableY)
    if(start+index >= this.leden.length) return "";

    let item = this.leden[start+index]

    let retContent = "<p>" +
      item.voorletters +
      (item.tussenvoegsel != "" ? " "+item.tussenvoegsel : "") +
      " "+item.naam +
    "</p>";

    retContent += "<p>" +
      item.adres +
      " "+item.huisnummer +
      (item.toevoeging != "" ? ` ${item.toevoeging}` : "") +
    "</p>";

    retContent += "<p>" +
        item.postcode +
        "  "+item.plaats +
        "</p>";

    if(item.land != "" && item.land != "NL") {
      retContent += "<p>" +
          item.land +
          "</p>";
    }

    return retContent;
  }


  getContentCords(tableX: number, tableY: number): number {
    let x = tableX - 1;
    let y = tableY - 1;
    return (y * this.x) + x;
  }

  async update() {
    let ledenPre = await api.get('leden/getall');
    ledenPre.forEach((obj: any) => {
      obj.geslacht = IntToGeslacht(obj.geslacht);
      obj.betaalwijze = IntToBetaalwijze(obj.betaalwijze);
      obj.soortlid = IntToLidType(obj.soortlid);
      obj.openstaand = (obj.bedrag - obj.betaald).toFixed(2);
      obj.voldaan = (obj.bedrag <= obj.betaald);
      obj.inschrijvingFormated = this.formatDate(obj.inschrijving);
      obj.eindinschrijvingFormated = this.formatDate(obj.eindinschrijving);
    });
    this.leden = ledenPre;

    console.log(this.leden.length);
  }

  private formatDate (date) {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return `${day}-${month}-${year}`
  }

  print(){
    var mywindow = window.open('', 'PRINT');

    mywindow.document.write('<html><head>');
    mywindow.document.write("<style>" +
        `@page {
        size: ${this.pageWidth}mm ${this.pageHeight}mm;
        }
        @media print {
          table {
            page-break-after: always;
            margin-left: ${this.xMargin}mm;
            margin-top: ${this.yMargin}mm;
          }
          td {
            width: ${this.xSize}mm;
            max-width: ${this.xSize}mm;
            height: ${this.ySize}mm;
            max-height: ${this.ySize}mm;
            margin-right: ${this.xPadding}mm;
            margin-bottom: ${this.yPadding}mm;
            text-align: center;
          }
        }
        </style>`)
    mywindow.document.write('</head><body >');
    mywindow.document.write(document.getElementById("printarea").innerHTML);
    mywindow.document.write('</body></html>');

    //mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    //mywindow.close();
    return true;
  }
}

